
import lang from "~/mixins/lang.js";

const internalRedirects = {
  "/": "/en-AE/",
  "/en-AE/ma": "/en-AE/",
  "/en-KW/ma": "/en-KW/",
  "/en-EG/ma": "/en-EG/",
  "/fr": "/fr/ma",
  "/fr/": "/fr/ma",
  "/security": "/en-AE/security",
  "/en-ae/careem-now": "/en-AE/food",
  "/en-ae/careem-now/": "/en-AE/food",
  "/careem-now": "/en-AE/food",
  "/food": "/en-AE/food",
  "/en-AE/delivery/": "/en-AE/box",
  "/en-AE/delivery": "/en-AE/box",
  "/en-AE/quik-grocery/": "/en-AE/groceries",
  "/en-AE/quik-grocery": "/en-AE/groceries",
  "/ar-AE/quik-grocery/": "/ar-AE/groceries",
  "/ar-AE/quik-grocery": "/ar-AE/groceries",
  "/careem-ride": "/en-AE/ride",
  "/fare-estimator": "/en-AE/ride",
  "/ride": "/en-AE/ride",
  "/careemplus": "/en-AE/cplus",
  "/cplus": "/en-AE/cplus",
  "/en-ae/careem-pay/": "/en-AE/pay",
  "/en-ae/careem-pay": "/en-AE/pay",
  "/careem-pay": "/en-AE/pay",
  "/pay": "/en-AE/pay",
  "/en-AE/pay-bills": "/en-AE/pay",
  "/en-AE/send-money": "/en-AE/pay",
  "/about-careem": "/en-AE/about-us",
  "/our-story": "/en-AE/about-us",
  "/en-ae/our-story": "/en-AE/about-us",
  "/en-ae/our-story/": "/en-AE/about-us",
  "/ar-ae/our-story/": "/ar-AE",
  "/ar-ae/our-story": "/ar-AE",
  "/about-us": "/en-AE/about-us",
  "/careers": "/en-AE/life-at-careem",
  "/life-at-careem": "/en-AE/life-at-careem",
  "/terms": "/en-AE/terms-of-service",
  "/en-ae/terms": "/en-AE/terms-of-service",
  "/en-ae/terms/": "/en-AE/terms-of-service",
  "/ar-ae/terms": "/en-AE/terms-of-service",
  "/ar-ae/terms/": "/en-AE/terms-of-service",
  "/terms-of-service": "/en-AE/terms-of-service",
  "/privacy": "/en-AE/privacy-policy",
  "/en-ae/privacy": "/en-AE/privacy-policy",
  "/privacy-policy": "/en-AE/privacy-policy",
  "/cleaning": "/en-AE/cleaning",
  "/our-social-impact": "/en-AE/our-social-impact",
  "/captains": "/en-AE/captains",
  "/quik-grocery": "/en-AE/quik-grocery",
  "/bill-payments": "/en-AE/pay",
  "/pay-bill": "/en-AE/pay",
  "/peer-to-peer": "/en-AE/pay",
  "/send-money": "/en-AE/pay",
  "/delivery": "/en-AE/delivery",
  "/engineering-at-careem": "/en-AE/engineering-at-careem",
  "/en-ae/careem-bike/": "/en-AE/bike",
  "/en-ae/careem-bike": "/en-AE/bike",
  "/en-ae/careem-bike-app-landing/": "/en-AE/bike",
  "/en-ae/careem-bike-app-landing": "/en-AE/bike",
  "/en-AE/salon-spa": "/en-AE/homeservices",
  "/en-AE/salon-spa/": "/en-AE/homeservices",
  "/en-AE/laundry/": "/en-AE/homeservices",
  "/en-AE/laundry": "/en-AE/homeservices",
  "/en-AE/cleaning/": "/en-AE/homeservices",
  "/en-AE/cleaning": "/en-AE/homeservices",
};
const externalRedirects = {
  "/academia/":"https://engineering.careem.com/tech/academia",
  "/academia":"https://engineering.careem.com/tech/academia",
  "/corporate/pay.php":
    "http://corporate.careem-engineering.com/corporate/pay.php",
  "/corporate": "http://corporate.careem-engineering.com/corporate/",
  "/signup": "https://app.careem.com/signup",
  "/drive": "https://drive.careem.com/?utm_source=web&utm_medium=careem.com",
  "/app": "https://app.careem.com/app",
  "/faqs": "https://help.careem.com/hc/en-us",
  "/help": "https://help.careem.com/hc/en-us",
  "/en-ae/faqs": "https://help.careem.com/hc/en-us",
  "/now-merchant-signup":
    "https://docs.google.com/forms/d/e/1FAIpQLSdhIUUxA7oY_bSx-eXGyvz8QdeuLAZ1j94w-RLbDIQHwEIv3Q/viewform",
  "/now-merchant-signup/":
    "https://docs.google.com/forms/d/e/1FAIpQLSdhIUUxA7oY_bSx-eXGyvz8QdeuLAZ1j94w-RLbDIQHwEIv3Q/viewform",

  "/en-ae/pay-bills": "/en-AE/pay",
  "/en-ae/pay-bills/": "/en-AE/pay",
  "/en-ae/send-money": "/en-AE/pay",
  "/en-ae/send-money/": "/en-AE/pay",
};
const regexRedirects = [
  {
    regex: /^\/trackride\/([a-zA-Z0-9]{1,14})[/]?$/,
    to: (m) => `https://app.careem.com/trackride/${m[1]}`,
  },
  {
    regex: /^\/signup\/([a-zA-Z0-9]{1,11})[/]?$/,
    to: (m) => `https://app.careem.com/signup/${m[1]}`,
  },
  {
    regex: /^\/invite\/([a-zA-Z0-9]{1,11})[/]?$/,
    to: (m) => `https://app.careem.com/signup/${m[1]}`,
  },
  {
    regex: /^\/reset\/([a-zA-Z0-9]{1,11})[/]?$/,
    to: (m) => `https://app.careem.com/reset/${m[1]}`,
  },
];

const checkRegexRedirect = (path = "") => {
  const found = regexRedirects.find((r) => Boolean(path?.match(r.regex)));
  if (found) {
    const matches = path?.match(found.regex);
    const url = found.to?.(matches);
    return url;
  }
};

export default {
  name: "error",
  layout: "basic",
  mixins: [lang],
  props: {
    error: {
      type: Object,
      default: () => {
        "error";
      },
    },
  },
  data() {
    return {
      redirecting: true,
    };
  },
  mounted() {
    if (this.error?.statusCode == 404) {
      const path = $nuxt.$route.path;

      //checking for internal routes
      let redirect = internalRedirects[path];
      if (redirect)
        window.location.replace(this.insertCurrentQueries(redirect));
      else {
        //checking for external routes
        redirect = externalRedirects[path];
        if (redirect)
          window.location.replace(this.insertCurrentQueries(redirect));
        else {
          //checking for dynamic external routes with regex
          redirect = checkRegexRedirect(path);
          if (redirect)
            window.location.replace(this.insertCurrentQueries(redirect));
          else this.redirecting = false;
        }
      }
    } else {
      this.redirecting = false;
    }
  },
  methods: {
    insertCurrentQueries(url) {
      const currentQueries = this.$route.query;
      const paramPairs = Object.keys(currentQueries).map(
        (key) => `${key}=${currentQueries[key]}`
      );

      return `${url}?${paramPairs.join("&")}`;
    },
  },
};
